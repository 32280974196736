'use client';

import { Box, Button, Divider, Toolbar, Typography } from "@mui/material";
import dayjs from "dayjs";
import Link from "next/link";
import { useTheme } from '@mui/material/styles';

export const metadata = {
  title: 'DidThey?',
  description: 'DidThey? is a simple way to track your dog\'s activities.',
}

const styles = {
  legalBox: {
    flexGrow: 1,
  }
}

export default function LegalFooter() {
  const theme = useTheme();

  return (
    <Toolbar>
      <Typography variant="body2">
        © {dayjs().year().toString()} DidThey LLC <br />{' '}
      </Typography>
      <Box sx={styles.legalBox} />
      <Link href="/help">
        <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>Help</Button>
      </Link>
      <Divider orientation="vertical" flexItem />
      <Box display={{ sm: 'block', xs: 'none' }}>
        <Link href="/policy/terms">
          <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>Terms</Button>
        </Link>
        <Link href="/policy/aup">
          <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>AUP</Button>
        </Link>
        <Link href="/policy/cookie">
          <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>Cookie</Button>
        </Link>
        <Link href="/policy/privacy">
          <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>Privacy</Button>
        </Link>
      </Box>
      <Box display={{ sm: 'none', xs: 'block' }}>
        <Link href="/policy">
          <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>Policy</Button>
        </Link>
      </Box>
    </Toolbar>
  )
}
